<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
      :customer-email="customerEmail"
    />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      class="mt-2 buttons-upgrade"
      variant="primary"
      :disabled="userPlan.plan === 1"
      @click="submit"
    >
      Choose SlothMover
    </b-button>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  name: "RecurringPaymentStandard",
  components: {
    StripeCheckout,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    userPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    //this.publishableKey = "pk_test_51ImbPzDDiHmKYHSMzKOHlC99wIr2Qpan6u04EfgV8gYEetvK0qsTAKTrZHPWFmVCBf2UChkatu2AV4DlbVf5Nanv00vrsRprOs";
    this.publishableKey = "pk_live_51ImbPzDDiHmKYHSM1VUow7R3CP2MN3sBJa7GlNu7NjSGVzCTLFf8guy5gLI5bNssOEXIJeQk9PmL54Lo6BWf7I4q009QcySCLs";

    const email = JSON.parse(localStorage.getItem("userData")).email
    return {
      loading: false,
      lineItems: [
        {
          //price: "price_1L5EtXDDiHmKYHSMnT9ivpoF", // Test
          price: "price_1L7hajDDiHmKYHSMgjrQGaUJ", // Live
          quantity: 1,
        },
      ],
      successURL: `${window.location.origin}/dashboard/home?successfulPayment=${window.btoa(
        email + ':standard'
      )}`,
      cancelURL: `${window.location.origin}/pages/pricing`,
      customerEmail: email,
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style></style>
