<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="text-black">
        Pricing Plans
      </h1>
      <p class="mb-1 pb-75 sub-description" style="font-weight: 400 !important;">
        <!-- All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs. -->
        Choose your SlothMove subscription with three options to meet your needs
      </p>
      <div class="d-flex align-items-center justify-content-center mb-2 pb-20">
        <h5 class="mr-1 mb-0">
          Monthly
        </h5>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h5 class="ml-50 mb-0">
          Annually
        </h5>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <div class="row d-flex justify-content-center pricing-card mb-5">
      <div class="col-12 col-md-11">

      <!--class="mx-auto" offset-lg="2" offset-sm-2-->
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <!-- Starter -->
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2 img-fluid"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <!-- <sup class="font-medium-1 font-weight-bold text-primary">£</sup> -->
                  <span class="pricing-basic-value extra-bold text-primary price"><!-- {{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }} -->FREE</span>
                  <!-- <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub> -->
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <!-- <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group> -->

              <div class="row d-flex justify-content-center">
                <div class="col-12 benefits  d-flex align-items-center mb-1" v-for="(data,index) in pricing.basicPlan.planBenefits" :key="index">
                  <img width="20px" :src="require('@/assets/images/illustration/information_icon.svg')" v-b-tooltip.hover.left="data.hoverInfo" alt="">
                  <p class="ml-1 mb-0">{{ data.title }}</p>
                </div>
              </div>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2 buttons-upgrade"
                variant="primary"
                @click="changeToFreePlan()"
                :disabled="userPlan.plan === 0"
              >
                Choose Free
              </b-button>
            </b-card>
          </div>
          <div class="col-sm-6 col-md-4">
            <!-- SlothMover -->
            <b-card
              class="popular"
              align="center"
            >
              <!-- <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>-->
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1 img-fluid"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2 price">
                  <sup class="font-medium-1 font-weight-bold text-primary price currency">£</sup>
                  <span class="pricing-basic-value extra-bold text-primary price">{{ monthlyPlanShow ? pricing.standardPlan.monthlyPrice:pricing.standardPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/
                    {{monthlyPlanShow ? 'month'  : 'annual'}}
                  </sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <!-- <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group> -->
              <!--/ plan benefit -->

              <div class="row d-flex justify-content-center">
                <div class="col-12 benefits d-flex align-items-center mb-1" v-for="(data,index) in pricing.standardPlan.planBenefits" :key="index">
                  <img width="20px" :src="require('@/assets/images/illustration/information_icon.svg')" v-b-tooltip.hover.left="data.hoverInfo" alt="">
                  <p class="ml-1 mb-0">{{ data.title }}</p>
                </div>
              </div>

              <!-- buttons -->
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2 buttons-upgrade"
                variant="primary"
                :disabled="userPlan.plan === 1"
              >
                 {{userPlan.plan === 1 ? `Free until ${userPlan.endDateFormatted}` : 'Upgrade'}}
              </b-button> -->
              <RecurringPaymentStandard :userPlan="userPlan"></RecurringPaymentStandard>
            </b-card>
          </div>
          <div class="col-sm-6 col-md-4">
            <!-- Jungle King -->
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2 img-fluid"
                alt="enterprise svg img "
              />
              <!--/ img -->
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2 price">
                  <sup class="font-medium-1 font-weight-bold text-primary price currency" style="margin-right: 5px!important">£</sup>
                  <span class="pricing-basic-value extra-bold text-primary price">{{ monthlyPlanShow ? pricing.enterprisePlan.monthlyPrice:pricing.enterprisePlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">
                    /{{monthlyPlanShow ? 'month'  : 'annual'}}
                  </sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <!-- <b-list-group
                v-for="(data,index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group> -->
              <!--/ plan benefit -->
              <div class="row d-flex justify-content-center">
                <div class="col-12 benefits d-flex align-items-center mb-1" v-for="(data,index) in pricing.enterprisePlan.planBenefits" :key="index">
                  <img width="20px" :src="require('@/assets/images/illustration/information_icon.svg')" v-b-tooltip.hover.left="data.hoverInfo" alt="">
                  <p class="ml-1 mb-0">{{ data.title }}</p>
                </div>
              </div>

              <!-- buttons -->
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2 buttons-upgrade"
                variant="primary"
                :disabled="userPlan.plan === 2"
                >
                 Upgrade
              </b-button> -->
              <RecurringPaymentJungleKing :userPlan="userPlan"></RecurringPaymentJungleKing>

            </b-card>
          </div>
        </div>
      </div>
    </div>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <!-- <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? Start with a 14-day FREE trial!
              </h3>
              <h5>You will get full access to with all the features for 14 days.</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
              >
                Start 14-day FREE trial
              </b-button>
            </div>

            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />

          </div>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <!-- <div class="pricing-faq">
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Let us help answer the most common questions.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,VBTooltip
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from "vuex";

import RecurringPaymentStandard from '@/views/pages/pricing/RecurringPaymentStandard.vue';
import RecurringPaymentJungleKing from './RecurringPaymentJungleKing.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import axiosCustom from '@axios';

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    RecurringPaymentStandard,
    RecurringPaymentJungleKing
},
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {}
    }
  },
  mounted(){
    // const userData = getUserData();
    // this.user = userData;
  },
  created() {
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    changeToFreePlan(){
      let body = JSON.stringify({ plan:0, email:this.user['email'] });

      axiosCustom.post("user/utils/changePlan", body)
        .then(response => response.text())
        .then(success =>{

          console.log(success);

          if (success == 'true') {

            this.$store.commit("app/setField", [
              "userPlan",
              {
                plan:0,
                endDate: this.userPlan["endDate"],
                endDateFormatted: this.userPlan["endDateFormatted"],
              },
            ]);

            // Redirect to dashboard-home
          this.$router.push({ name: "dashboard-home" }).then(()=>{
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Congratulations! 🎉`,
                  icon: "StarIcon",
                  variant: "success",
                  text: `You can now enjoy the benefits of your new membership!`,
                },
              });
          });
          }
        })
        .catch(error =>{
          console.log("error in Change Plan", error);
        });
    }
  },
  computed:{
    ...mapState("app", ["user","userPlan"]),
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

// .bolder{
//   font-family: 'Montserrat', sans-serif!important;
//   font-weight: 800!important;
// }

.text-black{
  color: black!important;
}

.benefits {
  padding-left: 5.5vw;
  color:black!important;

  @media screen and (max-width: 1500px) {
    padding-left: 2.7vw;
  }

   @media screen and (max-width: 1200px) {
    padding-left: 1.5vw;
  }

  @media screen and (max-width: 570px) {
    padding-left: 20vw;
  }

}

/* .buttons-upgrade {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
  font-weight: bold;
}

.buttons-upgrade:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #18a8d6 !important;
} */

.price{
  color: #1f476f!important
}

.currency{
  font-size: 2rem!important;
}

.sub-description{
 font-size: 18px;
}
</style>
